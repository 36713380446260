<template>
  <section class="dtc-main-section12 doc6 mt-1" id="">
    <header
      class="dtc-title"
      style="font-size: 20px; display: grid; place-items: start;font-family:DFKai-sb;"
    >
      附件4-1
    </header>
    <header
      class="mb-2"
      style="font-size:20px;display:grid;place-items:center;font-family:DFKai-sb;"
    >
      {{ schoolName }}學校{{ year }}學年度{{
        term == 1 ? "上" : "下"
      }}學期偏鄉護理菁英補助培育學校業務費及行政管理費核銷明細表
    </header>

    <header class="grid-5-dtc">
      <div>項目名稱</div>
      <div>數量</div>
      <div>單價</div>
      <div>總價</div>
      <div>備註說明</div>
    </header>
    <main class="grid-5-dtc grid-6-dtc2">
      <div>
        <nav>業務費</nav>
        <nav class="sub-3-row">
          <div>
            <div style="text-align: left; padding-left: 10px;">
              一、學生輔導費
            </div>
            <div style="text-align: left; padding-left: 38px;">
              (次數/單價1000)
            </div>
          </div>
          <div>
            <div style="text-align: left; padding-left: 10px;">
              二、事務性支出
            </div>
            <div
              style="text-align: left; padding-left: 25px;"
              v-for="(item, i) in affairFee"
              :key="i"
            >
              {{ i + 1 }}. {{ item.SitemName }}
            </div>
          </div>
          <div style="text-align: left; padding-left: 10px;">三、國內旅費</div>
        </nav>
      </div>
      <div class="sub-3-row">
        <div>
          <div v-for="(item, i) in tutoringFee" :key="i">
            {{ item.SitemName }} {{ item.Quantity }}{{ item.Unit }}
          </div>
        </div>

        <div class="pt-4">
          <div class="grid-2-dtc" v-show="affairFee[0]">
            <div></div>
            <div>
              <div
                v-for="(item, i) in affairFee"
                :key="i"
                style="text-align:center;padding-right:30px"
              >
                {{ item.Quantity }} {{ item.Unit }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="grid-2-dtc" v-show="travelFee[0]">
            <div></div>
            <div>
              <div
                v-for="(item, i) in travelFee"
                :key="i"
                style="text-align:center;padding-right:30px"
              >
                {{ item.Quantity }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-3-row">
        <div>
          <div
            v-for="(item, i) in tutoringFee"
            :key="i"
            style="text-align:right;padding-right:5px;"
          >
            ${{ $formatPrice(item.Price) }}
          </div>
        </div>
        <div class="pt-4">
          <div v-show="affairFee[0]">
            <div
              v-for="(item, i) in affairFee"
              :key="i"
              style="text-align:right;padding-right:5px;"
            >
              ${{ $formatPrice(item.Price) }}
            </div>
          </div>
        </div>
        <div>
          <div v-for="(item, i) in travelFee" :key="i" class="text-right-mode">
            ${{ $formatPrice(item.Price) }}
          </div>
        </div>
      </div>
      <div class="sub-3-row">
        <div>
          <div style="text-align:right;padding-right:5px;">
            ${{ $formatPrice(tutoringFeeTotal) }}
          </div>
        </div>
        <div class="pt-4">
          <div style="text-align:right;padding-right:5px;">
            ${{ $formatPrice(affairFeeTotal) }}
          </div>
        </div>
        <div>
          <div style="text-align:right;padding-right:5px;">
            ${{ $formatPrice(travelFeeTotal) }}
          </div>
        </div>
      </div>
      <div class="sub-3-row">
        <div style="text-align: left; padding-left: 10px;">
          公費生每輔導1次補助1000元整
        </div>
        <div class="pt-4">
          <div class="grid-2-dtc" v-show="affairFee[0]">
            <div style="grid-column: 1/-1;">
              <div
                v-for="(item, i) in affairFee"
                :key="i"
                style="text-align:left;padding-left:10px"
              >
                {{ item.Remark }}
              </div>
            </div>
          </div>
        </div>
        <div style="text-align: left; padding-left: 10px;">
          實施本計畫所需相關人員差旅費
        </div>
      </div>
    </main>

    <section class="grid-5-dtc grid-6-dtc2">
      <div>
        <nav>行政管理費</nav>
        <nav>
          <div
            v-for="(item, i) in administrativeFee"
            :key="i"
            style="text-align: left; padding-left: 25px;"
          >
            {{ i + 1 }}. {{ item.ItemName }}
          </div>
        </nav>
      </div>
      <div>
        <div class="grid-2-dtc" v-show="administrativeFee[0]">
          <div></div>
          <div>
            <div
              v-for="(item, i) in administrativeFee"
              :key="i"
              style="text-align:center;padding-right:30px"
            >
              {{ item.SitemName }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div v-show="administrativeFee[0]">
          <div
            v-for="(item, i) in administrativeFee"
            :key="i"
            style="text-align:right;padding-right:5px;"
          >
            ${{ $formatPrice(item.Price) }}
          </div>
        </div>
      </div>
      <div>
        <div>
          <div style="text-align:right;padding-right:5px;">
            ${{ $formatPrice(administrativeFeeTotal) }}
          </div>
        </div>
      </div>
      <div>
        <p style="text-align: left; padding-left: 10px;">
          1.本項費用不得超過業務費10%，並依左列事項核實核銷。
        </p>
        <p style="text-align: left; padding-left: 10px;">
          2.實施本計畫所需相關人員費用。
        </p>
      </div>
    </section>

    <header class="grid-5-dtc" style="padding:0px;margin:0px;border-top:none;">
      <div style="font-size:16px">合計</div>
      <div></div>
      <div></div>
      <div style="font-size:16px" class="text-right-mode">
        ${{ $formatPrice(totalFee) }}
      </div>
      <div></div>
    </header>

    <footer class="mt-3 mb-4 grid-footer-4">
      <div>承辦人</div>
      <div>承辦主管</div>
      <div>會計(財務)</div>
      <div>校長</div>
    </footer>
  </section>
</template>

<script>
import queryString from "query-string";
const names = ["項目名稱", "數量", "單價", "總價", "備註說明"];

export default {
  name: "totalSpend13",
  data() {
    return {
      names,
      schoolName: "",
      year: "",
      term: "",
      map: [],
      schoolefee: [],
      paper: [],
      mail: [],
      print: [],
      meal: [],
      other: [],
      water: [],
      ele: [],
      gas: [],
      clean: [],
      elevator: [],
      overtime: [],
      insurance: [],
      device: [],
      tutoringFee: [],
      affairFee: [],
      travelFee: [],
      administrativeFee: [],
      tutoringFeeTotal: 0,
      affairFeeTotal: 0,
      travelFeeTotal: 0,
      administrativeFeeTotal: 0,
      totalFee: "",
    };
  },
  async mounted() {
    //for check is same school-prevent see other school
    const { schoolcode: schoolNow } = queryString.parse(
      location.href.split("?")[1]
    );

    const loginHealthCode = sessionStorage.getItem("darcok").replace(/\"/g, "");

    const stuChoolArr = JSON.parse(sessionStorage.getItem("stuChool"));
    const roleOtalArr = JSON.parse(sessionStorage.getItem("roleOtal"));
    const isSchoolPerson = stuChoolArr.includes(loginHealthCode) ? true : false;

    const isAdminPerson = roleOtalArr.includes(loginHealthCode) ? true : false;

    if (!isSchoolPerson && !isAdminPerson) {
      return;
    }

    if (isSchoolPerson) {
      if (loginHealthCode != schoolNow) {
        return;
      }
    }

    //check Can see and then get data
    const {
      schoolcode: SchoolCode = "",
      byear: Byear = "109",
      term = 1,
      category: Category = "N",
    } = queryString.parse(location.href.split("?")[1]);
    this.term = term; // == 1 ? '上' : '下';
    this.year = Byear;
    let schoolArr = await window.axios.get("School/GetAll");
    this.schoolName = schoolArr.find(
      (s) => s.SchoolCode == SchoolCode
    ).SchoolName;
    try {
      this.map = await axios.get(
        `NurseMoney/Get?schoolcode=${SchoolCode}&byear=${Byear}&term=${term}&category=${Category}`
      );

      this.map = this.map[0];

      if (Boolean(this.map)) {
        this.schoolefee = this.map.nursemoneys;
      } else {
        this.schoolefee = [];
      }
      const businessFee = this.schoolefee.filter((s) =>
        s.Category.includes("業務費")
      );

      this.tutoringFee = this.schoolefee.filter((s) =>
        s.ItemName.includes("學生輔導費")
      );
      this.affairFee = businessFee.filter(
        (s) => !s.ItemName.includes("學生輔導費") && !s.ItemName.includes("旅")
      );

      this.travelFee = this.schoolefee.filter((s) => s.ItemName.includes("旅"));

      this.administrativeFee = this.schoolefee.filter((s) =>
        s.Category.includes("行政管理費")
      );

      this.tutoringFee.forEach((s) => {
        this.tutoringFeeTotal +=
          Number(s.Quantity * s.Price) > 0 ? Number(s.Quantity * s.Price) : 0;
      });
      this.affairFee.forEach((s) => {
        this.affairFeeTotal +=
          Number(s.Quantity * s.Price) > 0 ? Number(s.Quantity * s.Price) : 0;
      });
      this.travelFee.forEach((s) => {
        this.travelFeeTotal +=
          Number(s.Quantity * s.Price) > 0 ? Number(s.Quantity * s.Price) : 0;
      });
      this.administrativeFee.forEach((s) => {
        this.administrativeFeeTotal +=
          Number(s.SubTotal) > 0 ? Number(s.SubTotal) : 0;
      });
      this.totalFee =
        this.tutoringFeeTotal +
        this.affairFeeTotal +
        this.travelFeeTotal +
        this.administrativeFeeTotal;

      this.water = this.schoolefee.filter((s) => s.ItemName.includes("水費"));
      this.ele = this.schoolefee.filter((s) => s.ItemName.includes("電費"));
      this.gas = this.schoolefee.filter((s) => s.ItemName.includes("瓦斯"));
      this.clean = this.schoolefee.filter((s) =>
        s.ItemName.includes("大樓清潔")
      );
      this.elevator = this.schoolefee.filter((s) =>
        s.ItemName.includes("電梯保養")
      );
      this.overtime = this.schoolefee.filter((s) =>
        s.ItemName.includes("協辦本計畫")
      );
      this.insurance = this.schoolefee.filter((s) =>
        s.ItemName.includes("補充保險")
      );
    } catch (e) {
      alert(e);
    }
  },
};
</script>

<style lang="scss">
.dtc-main-section12 {
  margin: 0 auto;
  width: 1140px;
}
// }
.dtc-note {
  grid-column: 1/-1;
  text-align: left;
  display: grid;
  border: 1px solid var(--dtc-border-color);
  border-top: none;
  height: 268px;
  grid-template-columns: 1fr;
  padding: 7px;
  > div:not(:first-child) {
    padding-left: 9px;
  }
}
.grid-5-dtc {
  display: grid;
  margin-top: 1rem;
  grid-template-columns: 300px 1fr 100px 120px 250px;
  border: 1px solid black;
  > div {
    min-height: 40px;
    font-size: 14px;
    text-align: center;
    border-right: 1px solid black;
    padding-top: 10px;
    //line-height: 100px;
  }
  > div:last-child {
    border-right: none;
  }
}
.grid-2-dtc {
  display: grid;
  grid-template-columns: 30px 1fr;

  > div {
    // height: 21px;
    font-size: 14px;
    text-align: center;
    font-size: 16px;
  }
  > div:last-child {
    border-right: none;
  }
}

.grid-6-dtc2 {
  margin-top: 0px;
  border-top: none;

  > div {
    min-height: 220px;
    padding-top: 0px;
    font-size: 16px;
  }
  > div:first-child {
    display: grid;
    grid-template-columns: 30px 1fr;
    nav:first-child {
      padding-top: 0px;
      writing-mode: vertical-lr;
      font-size: 20px;
      border-right: 1px solid black;
    }
  }
}

.sub-3-row {
  display: grid;
  grid-template-rows: 70px 1fr 40px;
  > div {
    border-bottom: 1px solid black;
  }
  > div:last-child {
    border-bottom: none;
  }
}

.grid-footer-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-left: 10px;
  font-size: 24px;
  padding-left: 2rem;
  margin-bottom: 4rem;
}
.text-right-mode {
  text-align: right !important;
  padding-right: 5px;
}
@media print {
  @page {
    size: A4 landscape;
  }
}
</style>
